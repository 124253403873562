import mNavigationHeader from '@/modules/m-navigation-header/m-navigation-header.vue';
import oTile from '@/objects/o-tile/o-tile.vue';

export default {
  name: 'zone',
  components: {
    'm-navigation-header': mNavigationHeader,
    'o-tile': oTile
  },
  data() {
    return {
    };
  },
  methods: {

  },
  computed: {
    zoneId() {
      return this.$store.getters.zoneId
    },
    title() {
      let zoneTitle = ''
      if (this.zoneId !== null && this.$store.getters.content) {
        zoneTitle = this.$store.getters.content[this.zoneId].zoneTitle;
      }
      return zoneTitle;
    },
    intro() {
      let zoneIntro = ''
      if (this.zoneId !== null && this.$store.getters.content) {
        zoneIntro = this.$store.getters.content[this.zoneId].zoneIntro;
      }
      return zoneIntro;
    },
    spaces() {
      let spaces = []
      if (this.zoneId !== null && this.$store.getters.content) {
        spaces = this.$store.getters.content[this.zoneId].spaces;
      }
      return spaces;
    }
  }
};
