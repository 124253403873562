export const CONST = {
    API: {
        BASE_URL: 'https://continium.blockrocker.io/api',
        ROUTE: {
            DISPLAY: '/display/'
        },
        IMAGE: {
            BASE_URL: 'https://continium.blockrocker.io/'
        }
    },
    KEY: {
        REQUEST: {
            AUTHORIZATION: 'Authorization',
            BEARER: 'Bearer ',
            ACCEPT: 'Accept',
            CONTENT_TYPE: 'Content-Type',
            APP_KEY: 'Application-Key',
            APP_SECRET: 'Application-Secret'
        }
    },
    VALUE: {
        REQUEST: {
            ACCEPT_JSON: 'application/json',
            ACCEPT_MULTIPART: "multipart/form-data"
        },
        DATA: {
            URL: '/data/display.json'
        }
    }
};
