import { render, staticRenderFns } from "./m-navigation-header.vue?vue&type=template&id=581e470a&scoped=true"
import script from "./m-navigation-header.js?vue&type=script&lang=js&external"
export * from "./m-navigation-header.js?vue&type=script&lang=js&external"
import style0 from "./m-navigation-header.scss?vue&type=style&index=0&id=581e470a&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "581e470a",
  null
  
)

export default component.exports