import { mapState } from 'vuex';
import oButton from '../../objects/o-button/o-button.vue';
import oButtonBack from '../../objects/o-button-back/o-button-back.vue';

export default {
  name: 'mNavigationHeader',
  components: {
    'o-button': oButton,
    'o-button-back': oButtonBack
  },
  props: {
    showBackToHome: {
      default: false,
      type: Boolean
    },
    showBackToZone: {
      default: false,
      type: Boolean
    },
    showStop: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
    };
  },
  methods: {
    getToZone() {
      return `/display/${this.$store.state.zoneId}/zone`;
    },
    getToStop() {
      return `/display/${this.$store.state.zoneId}/home`;
    }
  },
  computed: {
    ...mapState(['zoneId'])
  }
};
