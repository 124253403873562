/* eslint-disable no-console */
import { mapState } from 'vuex';
import {CONST} from "@/utils/Constants";

export default {
  name: 'oBlock',
  props: {
    space: Object,
    space_id: Number
  },
  methods: {
    getTo() {
      return `space/${this.space_id}`;
    }
  },
  computed: {
    ...mapState(['zoneId']),
    title() {
      if(this.zoneId && this.space) {
        return this.space.title;
      }
      return '';
    },
    img_src() {
      if(this.zoneId && this.space) {
        // return process.env.VUE_APP_IMAGE_BASE_URL + this.space.preview_image;
        return CONST.API.IMAGE.BASE_URL + this.space.preview_image;
      }
      return '';
    }
  }
};
